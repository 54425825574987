$BACKGROUND_COLOR: #f6f6f6;
$ERROR_RED: #e3191f;
$MAIN_COLOR: #428538;
$MAIN_COLOR_OPACITY: '#97D185';
$SECONDARY_COLOR: #97D185;
$SECONDARY_COLOR_OPACITY: rgba(38, 34, 97, 0.5) !default;
$BLACK_TEXT_COLOR: #282b30;
$GRAY_TEXT_COLOR: #d7dfe3;
$MENU_ITEM_ACTIVE: #428538;
$MENU_ICON_ACTIVE: #fff;
$APP_SEARCH_COLOR: #428538;

:export {
  BACKGROUND_COLOR: $BACKGROUND_COLOR;
  ERROR_RED: $ERROR_RED;
  MAIN_COLOR: $MAIN_COLOR;
  MAIN_COLOR_OPACITY: $MAIN_COLOR_OPACITY;
  SECONDARY_COLOR_OPACITY: $SECONDARY_COLOR_OPACITY;
  SECONDARY_COLOR: $SECONDARY_COLOR;
  BLACK_TEXT_COLOR: $BLACK_TEXT_COLOR;
  GRAY_TEXT_COLOR: $GRAY_TEXT_COLOR;
  MENU_ITEM_ACTIVE: $MENU_ITEM_ACTIVE;
  MENU_ICON_ACTIVE: $MENU_ICON_ACTIVE;
  APP_SEARCH_COLOR: $APP_SEARCH_COLOR;
}

body {
  --BACKGROUND_COLOR: #{$BACKGROUND_COLOR};
  --ERROR_RED: #{$ERROR_RED};
  --MAIN_COLOR: #{$MAIN_COLOR};
  --MAIN_COLOR_OPACITY: #{$MAIN_COLOR_OPACITY};
  --SECONDARY_COLOR_OPACITY: #{$SECONDARY_COLOR_OPACITY};
  --SECONDARY_COLOR: #{$SECONDARY_COLOR};
  --BLACK_TEXT_COLOR: #{$BLACK_TEXT_COLOR};
  --GRAY_TEXT_COLOR: #{$GRAY_TEXT_COLOR};
  --MENU_ITEM_ACTIVE: #{$MENU_ITEM_ACTIVE};
  --MENU_ICON_ACTIVE: #{$MENU_ICON_ACTIVE};
  --APP_SEARCH_COLOR: #{$APP_SEARCH_COLOR};
}
